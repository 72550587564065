import { defineComponent } from 'vue';
export default defineComponent({
  name: 'PasswordInput',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      "default": ''
    }
  },
  data: function data() {
    return {
      showPassword: false
    };
  },
  methods: {
    updateValue: function updateValue(newValue) {
      this.$emit('input', newValue);
    }
  }
});